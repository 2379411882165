<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.customer"
                :label="$t(nameLabel)"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t(nameLabel) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="saveCustomer"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {},
  data: (vm) => ({
    topic: vm.$i18n.t("customer.customer"),
  }),
  computed: {
    nameLabel() {
      if (this.isTraditional === true) {
        return "customer.name_tc";
      }
      return "customer.name_sc";
    },
  },
  mixins: [BaseDialog],
  methods: {
    saveCustomer() {
      console.log("saveCustomer");
      let editedItem = this._.cloneDeep(this.item);
      if (this.isTraditional === true) {
        editedItem.name_tc = this.item.customer;
      } else {
        editedItem.name_sc = this.item.customer;
      }
      console.log("editedItem", editedItem);
      this.$emit("onSave", editedItem);
    },
  },
};
</script>