<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>{{ $t('commodity.new-image') }}</v-card-subtitle>
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="image_binary"
                    accept="image/jpeg, image/png"
                    show-size
                    :label="$t('commodity.image')"
                    :prepend-icon="''"
                    append-icon="mdi-file-image-outline"
                    multiple
                    :counter="imageCounter()"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    :disabled="blockUpdate"
                    @click="createImages"
                  >{{ $t("update") }}</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>{{ $t('commodity.exist-image') }}</v-card-subtitle>
              <v-row dense>
                <v-col>
                  <template v-if="hasImage">
                    <v-radio-group 
                      v-model="item.cover" 
                      row
                      @change="updateCover"
                    >
                      <template v-for="img in images">
                        <div 
                          class="mr-4" 
                          style="display:flex;outline-width:1px;outline-style: inset;border-radius: 5px;">
                        <v-radio class="mr-1 mt-1 mb-1" :value="img.id">
                          <template v-slot:label>
                            <v-img
                              :src="getImage(img)"
                              max-width="100"
                            ></v-img>
                          </template>
                        </v-radio>
                        <v-icon large @click="delImage(img.id)">mdi-delete</v-icon>
                        </div>
                      </template>
                    </v-radio-group>
                  </template>
                  <template v-else>
                    <v-img :src="noImage" max-width="100"></v-img>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import noImage from "@/assets/no-image.jpg"
export default {
  props: {
    images: [],
    updateList: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.image"),
    image_binary: [],
    noImage: noImage,
    imageMaxCounter: 5
  }),
  mixins: [BaseDialog],
  computed: {
    blockUpdate(){
      if (this.image_binary) {
        return (this.image_binary.length > 0 && this.image_binary.length <= this.imageCounter()) ? false : true
      }
      return true
    },
    hasImage() {
      return this.images ? this.images.length > 0 : false
    },
  },
  methods: {
    imageCounter(){
      return Math.max(this.imageMaxCounter - (this.images ? this.images.length : 0), 0)
    },
    getImage(item) {
      return item.src
    },
    createImages(){
      this.$emit('onSave',this.item.id,this.image_binary)
      this.image_binary = null
    },
    delImage(img) {
      // console.log('delImage',this.item.id,img)
      this.$emit('deleteCommodityImage',this.item.id,img,this.updateList)
    },
    updateCover() {
      // console.log('update cover',this.item.cover)
      this.$emit('updateCommodityCover',this.item.id,this.item.cover,this.updateList)
    },
  },
  watch: {
    // images(val){
    //   console.log('images',val)
    // }
  },
};
</script>