<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-select
              v-model="workplace"
              :label="$t('container.workplace')"
              :items="workplace_items"
              :item-text="item => $t(item.text)"
            ></v-select>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
    props: {
        workplace_items: [],
    },
    data: (vm) => ({
        topic: vm.$i18n.t("container.workplace"),
        workplace: null,
    }),
    mixins: [BaseDialog],
    computed: {
        allowSave() {
            return !(this.workplace)
        },
    },
    methods: {
        save(){
            this.$emit('onSave',this.workplace)
        },
        close() {
            this.$emit("onClose");
        },
    },
}
</script>