<template>
    <v-autocomplete
      :value="data"
      :label="label"
      :loading="loading"
      :items="allItems"
      :item-text="item_text"
      :item-value="item_value"
      :search-input.sync="search"
      :placeholder="placeholder"
      dense
      :outlined="outlined"
      flat
      hide-details
      clearable
      @input="handleInput"
      @compositionstart="flag=false"
      @compositionend="flag=true"
      @focus="focus"
      :return-object="returnObject"
      :ref="selfRef"
      @keyup.right="keyPress"
    >
      <template #label v-if="required">
        <span class="red--text"><strong>* </strong></span>{{ label }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.commodity_number }}
            <template v-if="item.name">
              【{{ item.name }}】
            </template>
            <template v-if="item.customer">
              〈{{ item.customer.name_tc }}〉
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>報關品項：{{ item.customs_type.name_tc }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar :tile="true" size="40">
          <img :src="item.image.file" v-if="item.image">
        </v-list-item-avatar>
      </template>
      <template v-slot:prepend-item>
        <template v-if="prepend">
          <v-list-item
            :title="prependTitle"
            :value="prependTitle"
            @click="onPrependClickEvent"
          >
          <v-list-item-content>
            <v-list-item-title>{{ $t(prependTitle) }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-autocomplete>
  </template>
  <script>
  import CommonAutocomplete from "./CommonAutocomplete";
  export default {
    mixins: [CommonAutocomplete],
  };
  </script>
  