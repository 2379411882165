<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <CustomsTypeDialog
      :show="showDialog"
      :action="dialogAction"
      :item="dialogItem"
      v-on:onClose="onDialogClose"
      v-on:onSave="onDialogSave"
      :unit_items="unit_items"
    >
    </CustomsTypeDialog>
    <DeliverTypeDialog
      :show="showDialog2"
      :action="dialogAction2"
      :item="dialogItem2"
      v-on:onClose="onDialogClose2"
      v-on:onSave="onDialogSave2"
    >
    </DeliverTypeDialog>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.commodity_number"
                :label="$t('commodity.number')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('commodity.number') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.name"
                :label="$t('commodity.name')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="deliver_type"
                :label="$t('commodity.deliver-type')"
                :placeholder="''"
                url="/commodity/deliver-type/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[deliver_type_cache]"
                :extra_condition="{
                  enable: true
                }"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <v-btn
                @click="createDeliverType"
                color="primary"
              >
                <v-icon>{{ $t('container-box.create_deliver_type') }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="customs_type"
                :label="$t('commodity.customs-type')"
                :placeholder="''"
                url="/commodity/customs-type/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[customs_type_cache]"
                :extra_condition="{
                  enable: true
                }"
                :required="true"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <v-btn
                @click="createCustomsType"
                color="primary"
              >
                <v-icon>{{ $t('container-box.create_customs_type') }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="item.supplier"
                :label="$t('supplier.supplier')"
                :placeholder="''"
                url="/supplier/supplier/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[item.supplier]"
                :extra_condition="{
                  enable: true
                }"
                :required="true"
              ></CommonAutocomplete>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="item.customer"
                :label="$t('customer.customer')"
                :placeholder="''"
                url="/customer/customer/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[item.customer]"
                :extra_condition="{
                  enable: true
                }"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <v-checkbox
              v-model="isCustomerSpecial"
              :label="$t('commodity.customer-special')"
              disabled
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-file-input
                v-model="image_binary"
                accept="image/jpeg, image/png"
                show-size
                :label="$t('commodity.image')"
                :prepend-icon="''"
                append-icon="mdi-file-image-outline"
                multiple
              ></v-file-input>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                @click="createImages"
              >{{ $t("update") }}</v-btn>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-radio-group 
              v-model="item.cover" 
              row
              @change="updateCover"
            >
              <template v-for="img in images">
                <v-radio :value="img.id">
                  <template v-slot:label>
                    <v-img
                      :src="getImage(img)"
                      max-width="100"
                    ></v-img>
                    <v-icon @click="delImage(img.id)">mdi-delete</v-icon>
                  </template>
                </v-radio>
              </template>
              
            </v-radio-group>
          </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import CustomsTypeDialog from "./CustomsTypeDialog";
import DeliverTypeDialog from "./DeliverTypeDialog";
import { unit_items } from "@/definition.js"
export default {
  props: {
    new_customs_type: {},
    new_deliver_type: {},
    // images: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.commodity"),
    showDialog: false,
    dialogAction: null,
    defaultItem: {},
    dialogItem: {},
    customs_type: null,
    customs_type_cache: null,

    showDialog2: false,
    dialogAction2: null,
    defaultItem2: {},
    dialogItem2: {},
    deliver_type: null,
    deliver_type_cache: null,

    unit_items: unit_items,
  }),
  computed: {
    allowSave() {
      return !(this.item.commodity_number &&
      this.customs_type &&
      this.item.supplier)
    },
    isCustomerSpecial(){
      return this.item.customer!==null
    },
  },
  components: {
    CommonAutocomplete,
    CustomsTypeDialog,
    DeliverTypeDialog
  },
  mixins: [BaseDialog],
  methods: {
    save(){
      let editedItem = this._.cloneDeep(this.item);
      editedItem.customs_type = this.customs_type
      editedItem.deliver_type = this.deliver_type
      this.$emit("onSave", editedItem);
    },
    close() {
      this.item = {};
      this.customs_type = null
      this.customs_type_cache = null
      this.deliver_type = null
      this.deliver_type_cache = null
      this.$emit("onClose");
    },
    createCustomsType() {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.dialogAction = this.action_items.create;
      this.showDialog = true
    },
    onDialogClose(item) {
      this.showDialog = false;
    },
    onDialogSave(item) {
      this.showDialog = false;
      this.$emit("onCreateCustomsType", item);
    },
    createDeliverType() {
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      this.dialogAction2 = this.action_items.create;
      this.showDialog2 = true
    },
    onDialogClose2(item) {
      this.showDialog2 = false;
    },
    onDialogSave2(item) {
      this.showDialog2 = false;
      this.$emit("onCreateDeliverType", item);
    },
  },
  watch: {
    new_customs_type(val, oldVal) {
      this.customs_type = val.id
      this.customs_type_cache = val
    },
    new_deliver_type(val, oldVal) {
      this.deliver_type = val.id
      this.deliver_type_cache = val
    },
    item(val){
      console.log('item',val)
      if (this.action === this.action_items.edit) {
        if (val.customs_type) {
          this.customs_type = val.customs_type.id
          this.customs_type_cache = val.customs_type
        }
        
        if (val.deliver_type) {
          this.deliver_type = val.deliver_type.id
          this.deliver_type_cache = val.deliver_type
        }
      }
    },
  },
};
</script>
