<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-select
              v-model="container"
              :label="$t('container.container')"
              :items="container_items"
              :item-text="item => $t(item.text)"
            ></v-select>
          </v-row>
        </v-container>
        <v-card>
          <v-data-table
            :headers="summary_headers"
            :items="[selected_summary,new_summary]"
            :hide-default-footer="true"
          >
            <template v-slot:item.cubic_feet="{ item }">
              {{ fixedFloat(item.cubic_feet) }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    summary_headers: [],
    container_items: [],
    selected_summary: {},
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.container"),
    new_summary: {
      type: vm.$i18n.t("container.summary"),
      box_amount: "-",
      cubic_feet: "-",
      gross_weight: "-",
    },
    container: null,
  }),
  mixins: [BaseDialog],
  computed: {
    allowSave() {
      return !(this.container)
    },
  },
  methods: {
    save() {
      console.log("dialog save");
      let editedItem = this._.cloneDeep(this.item);
      editedItem.container = this.container
      editedItem.total_box_amount = this.new_summary.box_amount
      editedItem.total_cubic_feet = this.new_summary.cubic_feet
      editedItem.total_gross_weight = this.new_summary.gross_weight
      this.$emit("onSave", editedItem);
    },
    close() {
      this.container = null;
      this.$emit("onClose");
    },
  },
  watch: {
    container(val, oldVal) {
      let target = this.container_items.find((obj) => {
        return obj.value == val;
      });
      console.log(target);
      if (target) {
        this.new_summary.box_amount =
        target.total_box_amount + this.selected_summary.box_amount;
        this.new_summary.cubic_feet =
          target.total_cubic_feet + this.selected_summary.cubic_feet;
        this.new_summary.gross_weight =
          target.total_gross_weight + this.selected_summary.gross_weight;
      } else {
        this.new_summary.box_amount = "-"
        this.new_summary.cubic_feet = "-"
        this.new_summary.gross_weight = "-"
      }
      
    },
    selected_summary(val, oldVal) {
      console.log('watch selected_summary')
      this.new_summary.box_amount =
        target.total_box_amount + val.box_amount;
      this.new_summary.cubic_feet =
        target.total_cubic_feet + val.cubic_feet;
      this.new_summary.gross_weight =
        target.total_gross_weight + val.gross_weight;
    }
  },
};
</script>