<template>
  <v-container
    id="kontainer-box.filter"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        {{ $t("container.stuffing-date") }}
        <CommonDateFilter v-on:setTimeRange="setTimeRange"></CommonDateFilter>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="4"
        md="2"
      >
        <v-text-field
          v-model="filterItem.code"
          :label="$t('container.code')"
          outlined
          dense
          clearable
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-text-field
          v-model="filterItem.container__container_number__contains"
          :label="$t('container.number')"
          outlined
          dense
          clearable
        ></v-text-field>
      </v-col>
    <!-- </v-row>
    <v-row dense> -->
      <v-col
        cols="6"
        md="3"
      >
        <CommonAutocomplete
          v-model="filterItem.supplier"
          :label="$t('supplier.supplier')"
          url="/supplier/supplier/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <CommonAutocomplete
          v-model="filterItem.customer"
          :label="$t('customer.customer')"
          url="/customer/customer/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
        ></CommonAutocomplete>
      </v-col>
      <!-- <v-col
        cols="6"
        md="3"
      >
        <v-select
          v-model="filterItem.container__isnull"
          :label="$t('container.is_null')"
          :items="container_isnull_items"
          outlined
          dense
          clearable
          :clear-icon-cb="onChange"
          :item-text="(item) => $t(item.text)"
        ></v-select>
      </v-col> -->
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="onChange"
        >
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
import CommonDateFilter from "./CommonDateFilter";
import CommonAutocomplete from "./CommonAutocomplete";
// import { container_isnull_items } from "@/definition.js";
export default {
  data: (vm) => ({
    filterItem: {
      container_number: null,
      supplier: null,
      customer: null,
      container__stuffing_date__range: {
        start: new Date(),
        end: new Date(),
      },
      // container__isnull: true,
    },
    // container_isnull_items: container_isnull_items,
  }),
  components: {
    CommonDateFilter,
    CommonAutocomplete,
  },
  mixins: [BaseFilter],
  methods: {
    setTimeRange(val) {
    //   console.log("setTimeRange");
    //   console.log(val);
      this.timeRange = val;
    },
    onChange() {
      this.filterItem.container__stuffing_date__range = this.timeRange;
      this.$emit("onFilterChange", this.filterItem);
    },
  },
};
</script>
