<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <CustomsTypeDialog
      :show="showCustomsTypeDialog"
      :action="dialogAction"
      :item="customsTypeDialogItem"
      v-on:onClose="onCustomsTypeDialogClose"
      v-on:onSave="onCustomsTypeDialogSave"
      :unit_items="unit_items"
    >
    </CustomsTypeDialog>
    <DeliverTypeDialog
      :show="showDeliverTypeDialog"
      :action="dialogAction"
      :item="deliverTypeDialogItem"
      v-on:onClose="onDeliverTypeDialogClose"
      v-on:onSave="onDeliverTypeDialogSave"
    ></DeliverTypeDialog>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <!-- <v-col>
              <v-text-field
                v-model="item.name"
                :label="$t('commodity.name')"
              ></v-text-field>
            </v-col> -->
            <v-col>
              <v-text-field
                v-model="item.commodity_number"
                :label="$t('commodity.number')"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>{{ $t('commodity.number') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
              v-model="is_customer_special"
              :label="$t('commodity.customer-special')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="customs_type"
                :label="$t('commodity.customs-type')"
                :placeholder="''"
                url="/commodity/customs-type/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[customs_type_cache]"
                :required="true"
                :no_data_action="true"
                v-on:noDataAction="triggerCreateCustomsType"
                :extra_condition="{
                  enable: true
                }"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <v-btn
                v-if="action===action_items.create"
                @click="createCustomsType"
                color="primary"
              >
                <v-icon>{{ $t('container-box.create_customs_type') }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="deliver_type"
                :label="$t('commodity.deliver-type')"
                :placeholder="''"
                url="/commodity/deliver-type/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[deliver_type_cache]"
                :no_data_action="true"
                v-on:noDataAction="triggerCreateDeliverType"
                :extra_condition="{
                  enable: true
                }"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <v-btn
                v-if="action===action_items.create"
                @click="createDeliverType"
                color="primary"
              >
                <v-icon>{{ $t('container-box.create_deliver_type') }}</v-icon>
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-file-input
                v-model="item.image_binary"
                accept="image/jpeg, image/png"
                show-size
                :label="$t('commodity.image')"
                :prepend-icon="''"
                append-icon="mdi-file-image-outline"
                multiple
                counter="5"
              ></v-file-input>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="saveCommodity"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import CustomsTypeDialog from "./CustomsTypeDialog";
import DeliverTypeDialog from "./DeliverTypeDialog";
import { unit_items } from "@/definition.js"
export default {
  props: {
    new_customs_type: {},
    new_deliver_type: {},
  },
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.commodity"),
    showCustomsTypeDialog: false,
    showDeliverTypeDialog: false,
    dialogAction: null,
    defaultItem: {},
    customsTypeDialogItem: {},
    deliverTypeDialogItem: [],
    unit_items: unit_items,
    customs_type: null,
    customs_type_cache: null,
    deliver_type: null,
    deliver_type_cache: null,
    is_customer_special: false,
  }),
  computed: {
    allowSave() {
      if (this.item.image_binary) {
        console.log(this.item.image_binary.length)
        return this.item.image_binary.length > 5
      }
      return !(this.item.commodity_number &&
      this.customs_type)
    },
  },
  components: {
    CommonAutocomplete,
    CustomsTypeDialog,
    DeliverTypeDialog
  },
  mixins: [BaseDialog],
  mounted() {},
  methods: {
    saveCommodity() {
      let editedItem = this._.cloneDeep(this.item);
      editedItem.customs_type = this.customs_type
      editedItem.deliver_type = this.deliver_type
      this.resetField()
      if (this.is_customer_special === false) {
        delete editedItem.customer
      }
      this.$emit("onSave", editedItem);
    },
    createCustomsType(customs_type=null) {
      this.customsTypeDialogItem = this._.cloneDeep(this.defaultItem);
      if (customs_type) {
        var target_field = this.isTraditional ? 'name_tc' : 'name_sc'
        this.customsTypeDialogItem[target_field] = customs_type
      }
      this.customsTypeDialogItem.order = 10000
      this.dialogAction = this.action_items.create;
      this.showCustomsTypeDialog = true
    },
    createDeliverType(deliver_type=null) {
      this.deliverTypeDialogItem = this._.cloneDeep(this.defaultItem);
      if (deliver_type) {
        var target_field = this.isTraditional ? 'name_tc' : 'name_sc'
        this.deliverTypeDialogItem[target_field] = deliver_type
      }
      this.dialogAction = this.action_items.create;
      this.showDeliverTypeDialog = true
    },
    onCustomsTypeDialogClose(item) {
      this.showCustomsTypeDialog = false;
    },
    onCustomsTypeDialogSave(item) {
      this.showCustomsTypeDialog = false;
      this.$emit("onCreateCustomsType", item);
    },
    onDeliverTypeDialogClose(item) {
      this.showDeliverTypeDialog = false;
    },
    onDeliverTypeDialogSave(item) {
      this.showDeliverTypeDialog = false;
      this.$emit("onCreateDeliverType", item);
    },
    resetField(){
      this.customs_type = null
      this.customs_type_cache = null
      this.deliver_type = null
      this.deliver_type_cache = null
    },
    triggerCreateCustomsType(customs_type) {
      this.createCustomsType(customs_type);
    },
    triggerCreateDeliverType(deliver_type) {
      this.createDeliverType(deliver_type);
    },
  },
  watch: {
    new_customs_type(val, oldVal) {
      this.customs_type = val.id
      this.customs_type_cache = val
    },
    new_deliver_type(val) {
      this.deliver_type = val.id
      this.deliver_type_cache = val
    },
  },
};
</script>
