<template>
  <v-container id="container-box-list" fluid>
    <div id="goTop" style="position: fixed;right: 0;top: 95%;z-index: 1;">
      <v-btn small @click="scrollToTop"><v-icon>mdi-arrow-collapse-up</v-icon></v-btn>
    </div>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <KontainerBoxDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      :container_items="container_items"
      :default_customer_box_code="customer_box_code"
      :default_customer_box_start="customer_box_start"
      :new_commodity="new_commodity"
      :new_customs_type="new_customs_type"
      :new_deliver_type="new_deliver_type"
      :new_supplier="new_supplier"
      :new_customer="new_customer"
      :commodity_image="dialogImage"
      :commodity_images="dialogImages"
      :commodity_image_ids="dialogImageIds"
      :code_error="code_error"
      :code_error_msg="code_error_msg"
      :used_payment_type="used_payment_type"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveNormalEvent"
      v-on:onSaveNext="onSaveNextEvent"
      v-on:onCreateCommodity="createCommodity"
      v-on:onGetCustomerDetail="getCustomerDetail"
      v-on:onClearDialog="clearDialog"
      v-on:onCreateSupplier="createSupplier"
      v-on:onCreateCustomer="createCustomer"
      v-on:onCreateCustomsType="createCustomsType"
      v-on:onCreateDeliverType="createDeliverType"
      v-on:getCommodity="getCommodity"
      v-on:updateImages="updateImages"
      v-on:createCommodityImageEvent="createCommodityImageEvent"
      v-on:updateCommodityCover="updateCommodityCover"
      v-on:deleteCommodityImage="deleteCommodityImage"
      v-on:clearCommodityImage="clearCommodityImage"
      v-on:checkCode="checkCode"
      v-on:checkPaymentType="checkPaymentType"
      ref="kontainerBoxDialog"
    ></KontainerBoxDialog>
    <KontainerDialog
      :show="showDialog2"
      :action="action2"
      :item="dialogItem2"
      :summary_headers="summary_headers"
      :selected_summary="[selected_summary]"
      v-on:onClose="onCloseEvent2"
      v-on:onSave="onSaveContainer"
    ></KontainerDialog>
    <KontainerBoxBindDialog
      :show="showDialog4"
      :action="action4"
      :item="dialogItem4"
      :summary_headers="summary_headers"
      :container_items="container_items"
      :selected_summary="selected_summary"
      v-on:onClose="onCloseEvent4"
      v-on:onSave="onBindContainer"
    ></KontainerBoxBindDialog>
    <CommodityDialog
      :show="showDialog3"
      :action="action3"
      :item="dialogItem3"
      v-on:onClose="onCloseEvent3"
      v-on:onSave="onSaveEvent3"
    ></CommodityDialog>
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="images"
      @hide="closeLightBox"
    ></vue-easy-lightbox>
    <CommodityImageDialog
      :show="showDialog5"
      :action="action"
      :item="dialogItem5"
      :images="dialogImages"
      v-on:deleteCommodityImage="deleteCommodityImage2"
      v-on:updateCommodityCover="updateCommodityCover2"
      v-on:onClose="onDialogClose5"
      v-on:onSave="createCommodityImageEvent2"
    ></CommodityImageDialog>
    <WorkplaceDialog
      :show="showDialog6"
      :workplace_items="workplace_items"
      v-on:onSave="moveWorkplace"
      v-on:onClose="onDialogClose6"
    ></WorkplaceDialog>
    <KontainerBoxSplitDialog
      :show="showDialog7"
      :origin_data="origin_data"
      v-on:onSave="saveSplitItem"
      v-on:onClose="onDialogClose7"
    ></KontainerBoxSplitDialog>
    <KontainerBoxFilter v-on:onFilterChange="onFilterChange"></KontainerBoxFilter>
    
    <!-- workplace -->
    <v-tabs v-model="currentWorkplaceIndex" class="mb-2">
      <v-tab :value="0" :key="0">{{ $t('container.all') }}</v-tab>
      <template v-for="workplace in workplaces">
        <v-tab :value="workplace.id">
          {{ $t('container.workplace')+parseWorkplaceId(workplace.id) }}
          <v-icon v-if="currentWorkplace==workplace.id" @click="closeWorkplace(workplace.id)">mdi-close</v-icon>
        </v-tab>
      </template>
      <v-tab @click="createWorkplace()">
        <v-icon>mdi-plus</v-icon>
        {{ $t('container.create-workplace') }}
      </v-tab>
    </v-tabs>
    <!-- container isnull -->
    <v-tabs>
      <v-tab @click="container_isnull=true">{{ $t('container.is_null') }}</v-tab>
      <v-tab @click="container_isnull=false">{{ $t('container.not_null') }}</v-tab>
    </v-tabs>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.summary')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="summary_headers"
        :items="summary_items"
        :hide-default-footer="true"
      >
        <template v-slot:item.cubic_feet="{ item }">
          {{ fixedFloat(item.cubic_feet, 2 , 0) }}
        </template>
        <template v-slot:item.gross_weight="{ item }">
          {{ fixedFloat(item.gross_weight, 2 , 0) }}
        </template>
      </v-data-table>
    </base-material-card>

    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container-box.list')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="createItem"
          :disabled="!currentWorkplace>0"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("container-box.create") }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="createContainer"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("container.create_container") }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="bindContainer"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("container.bind_container") }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="triggerMoveWorkplace"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("container.move_worplace") }}
        </v-btn>
      </v-col>
      <v-data-table
        v-model="selected"
        :headers="getHeaders()"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
        fixed-header
        :height="windowHeight*0.8"
        class="container-box-data-table"
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100, 200]"
          />
        </template>
        <template v-slot:item.sort_code="{ item }">
          {{ item.code }}
        </template>
        <template v-slot:item.end_box="{ item }">
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.supplier="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.customer__id="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.accreditation_number="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              :return-value.sync="data.accreditation_number"
              @save="updateRelation(data.id,'accreditation_number',data.accreditation_number)"
              @close="resetError()"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ data.accreditation_number ? data.accreditation_number : '-' }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field
                  v-model="data.accreditation_number" 
                  @input="checkAccreditationNumber(data,item)"
                  :error="accreditation_number_error"
                  :error-messages="accreditation_number_error_message"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.china_number="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              :return-value.sync="data.china_number"
              @save="updateRelation(data.id,'china_number',data.china_number)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ data.china_number ? data.china_number : '-' }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field v-model="data.china_number"></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.commodity_images="{ item }">
          <template v-for="data in item.relation">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <template v-if="data.commodity.image">
                  <v-img
                    class="mr-2"
                    v-on="on"
                    :src="(data.commodity.image.file)"
                    max-width="100"
                    @click="editCommodiyImages(data)"
                  ></v-img>
                </template>
                <template v-else>
                  <v-icon
                    large
                    class="mr-2"
                    v-on="on"
                    @click="editCommodiyImages(data)"
                  >mdi-tooltip-image</v-icon>
                </template>
                
              </template>
              <span>{{ $t("edit") }}{{ $t("commodity.image") }}</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:item.customs_type="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              @save="updateRelation(data.id,'customs_type',customs_type)"
              @open="editOpen(data.customs_type)"
              @close="editClose()"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ get_customs_type_name(data) }}<v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <CommonAutocomplete
                  v-model="customs_type"
                  url="/commodity/customs-type/"
                  :item_text="nameField"
                  item_value="id"
                  :query_field="nameFieldFilter"
                  :cache="[customs_type_cache]"
                  :extra_condition="{
                    enable: true
                  }"
                ></CommonAutocomplete>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.commodity_total_amount="{ item }">
          <template v-for="data in item.relation">
            <div>{{ data.commodity_amount }}</div>
          </template>
        </template>
        <template v-slot:item.total_gross_weight="{ item }">
          {{ fixedFloat(getWeight(item),2,0) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                :disabled="item.box_amount==1"
                @click="splitItem(item)"
              >mdi-content-cut</v-icon>
            </template>
            <span>{{ $t("container-box.split") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                :color="hasCommodityImage(item)?'green':''"
                :disabled="!hasCommodityImage(item)"
                @click="displayCommodityImage(item)"
              >mdi-image</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                @click="disableItem(item,$t('container.box'),item.id)"
              >mdi-block-helper</v-icon>
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import KontainerBoxDialog from "../dialogs/KontainerBoxDialog";
import KontainerBoxFilter from "../filters/KontainerBoxFilter";
import KontainerDialog from "../dialogs/KontainerDialog";
import KontainerBoxBindDialog from "../dialogs/KontainerBoxBindDialog";
import CommodityDialog from "../dialogs/CommodityDialog"
import BaseTable from "./BaseTable.vue";
import { action_items, container_isnull_items } from "@/definition.js";
import VueEasyLightbox from 'vue-easy-lightbox'
import CommodityImageDialog from "../dialogs/CommodityImageDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import WorkplaceDialog from "../dialogs/WorkplaceDialog";
import KontainerBoxSplitDialog from "../dialogs/KontainerBoxSplitDialog";
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    summary_headers: [
      {
        sortable: false,
        text: "",
        value: "type",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.gross_weight"),
        value: "gross_weight",
        width: "5%",
      },
    ],
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "sort_code",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_str"),
        value: "start_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.number_str"),
        value: "end_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.name"),
        value: "accreditation_number",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.number"),
        value: "china_number",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.image"),
        value: "commodity_images",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_total_amount",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.size_length"),
        value: "size_length",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.size_width"),
        value: "size_width",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.size_height"),
        value: "size_height",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.gross_weight"),
        value: "gross_weight",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.total_gross_weight"),
        value: "total_gross_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer__id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container.container_number",
        hide: [true],
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "container.stuffing_date",
        hide: [true],
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "container.cutoff_date",
        hide: [true],
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("container.transport-status"),
      //   value: "transport_status",
      //   hide: [true],
      // },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    container_items: [],
    action_items: action_items,
    defaultItem: {
      start_box: 1,
      payment_type: 1,
    },
    selected: [],
    total_summary: {
      type: vm.$i18n.t("container.all"),
      box_amount: 0,
      cubic_feet: 0,
      gross_weight: 0,
    },
    current_summary: {
      type: vm.$i18n.t("container.current"),
      box_amount: 0,
      cubic_feet: 0,
      gross_weight: 0,
    },
    selected_summary: {
      type: vm.$i18n.t("container.selected"),
      box_amount: 0,
      cubic_feet: 0,
      gross_weight: 0,
    },
    filterItem: {},
    customer_box_code: "",
    customer_box_start: "",
    new_commodity: {},
    new_customs_type: {},
    new_deliver_type: {},
    new_supplier: {},
    new_customer: {},
    // containerDialog
    showDialog2: false,
    action2: action_items.view,
    dialogItem2: {},
    defaultItem2: {},
    // commodityDialog
    showDialog3: false,
    action3: action_items.view,
    dialogItem3: {},
    defaultItem3: {},
    orig_dialogItem3: {},
    // containertBoxBindDialog
    showDialog4: false,
    action4: action_items.view,
    dialogItem4: {},
    defaultItem4: {},
    orig_dialogItem4: {},
    container_isnull_items: container_isnull_items,
    container_isnull: true,
    lightboxVisible: false,
    images: [],
    dialogImage: null,
    dialogImages: [],
    dialogImageIds: [],
    dialogItem5: {},
    showDialog5: false,
    defaultItem5: {},
    workplaces: [],
    customs_type: null,
    customs_type_cache: null,
    showDialog6: false,
    targetWorkplace: null,
    currentWorkplaceIndex: 0,
    currentWorkplace: 0,
    switchToLatest: false,
    code_error: false,
    code_error_msg: '',
    showDialog7: false,
    defaultItem7: {},
    origin_data: {},
    relations: [],
    accreditation_number_error: false,
    accreditation_number_error_message: null,
    used_payment_type: null,
  }),
  mixins: [BaseTable],
  computed: {
    summary_items() {
      return [this.total_summary, this.current_summary, this.selected_summary];
    },
    workplace_items() {
      let arr = []
      if (this.workplaces) {
        for (const [index, val] of Object.entries(this.workplaces)) {
          if (val.id !== this.currentWorkplace){
            arr.push({
              value: val.id,
              text: this.$i18n.t("container.workplace")+this.parseWorkplaceId(val.id)
            })
          }
        }
      }
      return arr
    },
  },
  components: {
    KontainerBoxDialog,
    KontainerBoxFilter,
    KontainerDialog,
    CommodityDialog,
    CommonAlertDialog,
    KontainerBoxBindDialog,
    VueEasyLightbox,
    CommodityImageDialog,
    CommonAutocomplete,
    WorkplaceDialog,
    KontainerBoxSplitDialog,
  },
  methods: {
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.dialogItem.workplace = this.currentWorkplace
      this.showDialog = true;
    },
    createContainer() {
      var boxes = [];
      if (this.selected.length > 0) {
        for (const [key, box] of Object.entries(this.selected)) {
          boxes.push(box.id);
        }
        this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
        this.dialogItem2.boxes = boxes;
        this.action2 = this.action_items.create;
        this.showDialog2 = true;
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"))
      }
    },
    bindContainer() {
      var boxes = [];
      if (this.selected.length > 0) {
        for (const [key, box] of Object.entries(this.selected)) {
          boxes.push(box.id);
        }
        this.getContainerDatas();
        this.dialogItem4 = this._.cloneDeep(this.defaultItem4);
        this.dialogItem4.boxes = boxes;
        this.action4 = this.action_items.create;
        this.showDialog4 = true;
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"))
      }
    },
    onCloseEvent(item) {
      this.showDialog = false;
      this.defaultItem = {start_box: 1,payment_type: 1,}
      this.customer_box_code = ""
      this.customer_box_start = ""
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.dialogImage = null
    },
    onSaveContainer(item) {
      this.showDialog2 = false;
      if (item.boxes) {
        item.total_box_amount = this.selected_summary.box_amount
        item.total_cubic_feet = this.selected_summary.cubic_feet
        item.total_gross_weight = this.selected_summary.gross_weight
        this.postApi(item, "/container/container/createContainer/");
      }
    },
    onCloseEvent2(item) {
      this.showDialog2 = false;
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
    },
    onBindContainer(item) {
      this.showDialog4 = false;
      if (item.boxes) {
        this.postApi(item, "/container/container/"+ item.container +"/bindContainer/");
        this.selected = []
      }
    },
    onCloseEvent4(item) {
      this.showDialog4 = false;
      this.dialogItem4 = this._.cloneDeep(this.defaultItem4);
    },
    editItem(item) {
      this.getContainerDatas();
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      this.dialogItem.container = item.container?item.container.id:null;
      this.dialogItem.commoditys = this.parseRelation(item.relation)
      let extra = JSON.parse(item.extra)
      if (extra) {
        if (Object.keys(extra).includes('stacker')) {
          let stacker_data = extra['stacker']
          this.dialogItem.hasStacker = true
          this.dialogItem.stacker_fee = stacker_data['fee']
          if (Object.keys(stacker_data).includes('currency')){
            this.dialogItem.stacker_currency = stacker_data['currency']
          }
          this.dialogItem.stacker_extra = stacker_data['extra']
        }
        if (Object.keys(extra).includes('china_deliver')) {
          let china_deliver_data = extra['china_deliver']
          this.dialogItem.hasChinaDeliverFee = true
          this.dialogItem.china_deliver_company = china_deliver_data['company']
          this.dialogItem.china_deliver_fee = china_deliver_data['fee']
          if (Object.keys(china_deliver_data).includes('currency')){
            this.dialogItem.china_deliver_currency = china_deliver_data['currency']
          }
          this.dialogItem.china_deliver_extra = china_deliver_data['extra']
        }
      }
      this.used_payment_type = item.payment_type
      this.action = action_items.edit;
      this.showDialog = true;
    },
    parseRelation(items){
      var commoditys = []
      items.forEach((item)=>{
        let extra = JSON.parse(item.commodity.extra)
        let images = []
        if (extra && Object.keys(extra).includes('images')) {
          images = extra['images']
        }
        commoditys.push({
          id: item.id,
          title: item.china_number ? item.china_number : item.commodity.commodity_number,
          commodity: item.commodity,
          commodity_id: item.commodity.id,
          commodity_amount: item.commodity_amount,
          image: item.commodity.image,
          images: images,
          customer: item.commodity.customer
        })
      })
      return commoditys
    },
    splitItem(item){
      this.showDialog7 = true;
      this.origin_data  = item
    },
    saveSplitItem(box, splits){
      console.log('saveSplitItem',box,splits)
      this.showDialog7 = false;
      this.postApi({splits:splits}, "/container/container_box/"+ box +"/splitContainerBox/")
    },
    getContainerDatas() {
      this.getApi("/container/container/", this.setContainerItems,false,{},{status: this.container_status.enable},{});
    },
    setContainerItems(data) {
      this.setListItems(data.results, this.container_items, "container_number");
    },
    setListItems(datas, target_items, text_key1 = "name", text_key2 = "", value_key = "id") {
      datas.forEach((data) => {
        target_items.push({
          text: data[text_key1]?data[text_key1]:data[text_key2],
          value: data[value_key],
          total_box_amount: data['total_box_amount'],
          total_cubic_feet: data['total_cubic_feet'],
          total_gross_weight: data['total_gross_weight'],
        });
      });
    },
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : ''
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    createCommodity(data) {
      if ('image_binary' in data) {
        this.postCommodityImageApi(data)
      } else {
        this.postApi(data, "/commodity/commodity/", this.setNewCommodity);
      }
    },
    postCommodityImageApi(data) {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/document/document/multi_create/";
      const formData = new FormData();
      data['image_binary'].forEach(image=>{
        formData.append("uploaded_images", image);  
      })
      // formData.append("file", data['image_binary']);
      // formData.append("name", data['image_binary']['name']);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, formData, config)
        .then(function(response) {
          // data['image'] = response.data.id
          var images = response.data.images
          data['image'] = images[0]
          data['extra'] = JSON.stringify({'images':images})
          currentObj.postApi(data, "/commodity/commodity/", currentObj.setNewCommodity);
        })
        .catch(function(error) {
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    onSaveNormalEvent(data) {
      this.defaultItem = {start_box: 1,payment_type: 1,}
      this.onSaveEvent(data);
      this.dialogImage = null
    },
    onSaveNextEvent(data) {
      this.onSaveEvent(data);
      this.defaultItem = {
        supplier: data.supplier,
        customer: data.customer,
        code: data.code,
        start_box: data.end_box + 1,
        payment_type: data.payment_type,
      };
      this.dialogImage = null
      this.createItem({});
    },
    editCommodity(item) {
      this.orig_dialogItem3 = this._.cloneDeep(item.commodity);
      this.dialogItem3 = this._.cloneDeep(item.commodity);
      this.action3 = action_items.edit;
      this.showDialog3 = true;
    },
    onCloseEvent3(item) {
      this.showDialog3 = false;
      this.dialogItem3 = this._.cloneDeep(this.defaultItem3);
    },
    onSaveEvent3(item) {
      this.showDialog3 = false;
      console.log("orig_dialogItem3 " + JSON.stringify(this.orig_dialogItem3));
      if (this._.isEqual(item, this.orig_dialogItem3) === false) {
        const diff = this.$common_util.difference(this.orig_dialogItem3, item);
        console.log("diff: " + JSON.stringify(diff));
        this.patchApi(item.id, diff,"/commodity/commodity/");
      }
    },
    getCustomerDetail(id){
      console.log('getCustomerDetail',id)
      this.getApi("/customer/customer/" + id + "/", this.setCustomerDetail);
    },
    setCustomerDetail(data){
      console.log('setCustomerDetail',data)
      this.customer_box_code = data.box_code
      // find same workplace data
      let existed_datas = this.items.filter(item=>(item.customer.id==data.id))
      if (existed_datas.length > 0) {
        var existed_code = null
        var existed_max_box = null
        for (const [key,value] of Object.entries(existed_datas)) {
          existed_code = value['code']
          existed_max_box = Math.max(existed_max_box,value['end_box'])
        }
        if (!data.box_code||data.box_code==existed_code) {
          this.customer_box_code = existed_code
          this.customer_box_start = existed_max_box + 1
        }
      }
    },
    clearDialog(){
      this.new_commodity = null
    },
    setNewCommodity(action, responseData) {
      this.new_commodity = responseData
    },
    createCustomsType(data){
      this.postApi(data, "/commodity/customs-type/", this.setNewCustomsType);
    },
    createDeliverType(data){
      this.postApi(data, "/commodity/deliver-type/", this.setNewDeliverType);
    },
    setNewCustomsType(action, responseData) {
      this.new_customs_type = responseData
    },
    setNewDeliverType(action, responseData) {
      this.new_deliver_type = responseData
    },
    createSupplier(data){
      this.postApi(data, "/supplier/supplier/", this.setNewSupplier);
    },
    setNewSupplier(action, responseData) {
      this.new_supplier = responseData
    },
    createCustomer(data){
      this.postApi(data, "/customer/customer/", this.setNewCustomer);
    },
    setNewCustomer(action, responseData) {
      this.new_customer = responseData
    },
    get_customs_type_name(item){
      return item.customs_type?this.getHanText(item.customs_type,"name"):""
    },
    getHeaders() {
      // const role = this.loginData.role
      const container_isnull = this.container_isnull
      return this.headers.filter(function(e) {
        return (e.hide === undefined || !e.hide.includes(container_isnull));
      });
    },
    hasCommodityImage(item) {
      // TODO 多商品時如何切換，先以第一樣商品是否有圖片為準
      return item.relation[0].commodity.image ? true : false
    },
    displayCommodityImage(item) {
      console.log(item)
      var extra = JSON.parse(item.relation[0].commodity.extra)
      this.getImages(extra['images'])
    },
    getImages(images){
      var params = {'id__in':images}
      this.getApi("/document/document/",this.setImages,false,params,{},{})
      
    },
    setImages(data){
      this.images = []
      data.results.forEach(img=>{
        this.images.push({
          title: img.name,
          src: img.file
        })
      })
      this.lightboxVisible = true;
    },
    closeLightBox() {
      this.lightboxVisible = false;
    },
    getCommodity(id){
      this.getApi("/commodity/commodity/"+id+"/",this.setDialogImage)
    },
    setDialogImage(data){
      this.dialogImage = data.image
      this.dialogImageIds = data.images
    },
    updateImages(image_ids){
      if (image_ids.length>0) {
        var params = {'id__in':image_ids}
        this.getApi("/document/document/",this.setAllImages,false,params,{},{},)
      } else {
        this.dialogImages = []
      }
      
    },
    setAllImages(data){
      this.dialogImages = []
      data.results.forEach(img=>{
        this.dialogImages.push({
          id: img.id,
          title: img.name,
          src: img.file
        })
      })
    },
    createCommodityImageEvent(commodity_id,image_binary){
      // console.log('createCommodityImageEvent',commodity_id,image_binary)
      const data = {}
      data['commodity'] = commodity_id
      data['image_binary'] = image_binary
      this.imageCreateHandler(data,this.action_items.create)
    },
    imageCreateHandler(data,action){
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/document/document/multi_create/";
      const formData = new FormData();
      data['image_binary'].forEach(image=>{
        formData.append("uploaded_images", image);  
      })
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, formData, config)
        .then(function(response) {
          var images = response.data.images
          currentObj.createCommodityImage(data['commodity'],images)
        })
        .catch(function(error) {
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    createCommodityImage(commodity_id,new_images){
      // console.log('createCommodityImage',commodity_id,new_images)
      this.postApi({new_images:new_images},"/commodity/commodity/"+commodity_id+"/addImage/",this.refreshImage)
    },
    updateCommodityCover(commodity_id,image_id,updateList){
      // console.log('updateCommodityCover',commodity_id,image_id)
      if (image_id) {
        const after_func = (updateList===true)?this.refreshImage2:this.refreshImage
        this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/changeCover/",after_func)
      }
    },
    refreshImage(action, data){
      // console.log('refreshImage',data)
      this.dialogImage = data.image
      this.dialogItem.cover = data.image ? data.image.id : null
      this.dialogImageIds = data.images
    },
    refreshImage2(action, data){
      this.$refs.kontainerBoxDialog.refreshTarget(data)
    },
    deleteCommodityImage(commodity_id,image_id,updateList){
      // console.log('deleteCommodityImage',commodity_id,image_id)
      const after_func = (updateList===true)?this.refreshImage2:this.refreshImage
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/deleteImage/",after_func)
    },
    disableItem (item,key="",value="") {
      if (confirm(this.$i18n.t('disable-confirm',{key:key,value:value}))) {
        this.action = action_items.edit
        var disable_item = {
          id: item.id,
          status: this.container_box_status.disable
        }
        this.onSaveEvent(disable_item)
      }
    },
    getUndispatchSummary() {
      let params = {}
      if (this.currentWorkplace>0) {
        params['workplace'] = this.currentWorkplace
      }
      params['container__isnull'] = this.container_isnull
      this.postApi(params,"/container/container_box/getSummary/",this.setSummary)
    },
    setSummary(action,data) {
      this.total_summary.type = this.currentWorkplace>0?this.$i18n.t('container.workplace')+this.parseWorkplaceId(this.currentWorkplace):this.$i18n.t("container.all")
      this.total_summary.box_amount = data.box_amount__sum?data.box_amount__sum:0
      this.total_summary.cubic_feet = data.cubic_feet__sum?data.cubic_feet__sum:0
      this.total_summary.gross_weight = data.gross_weight__sum
    },
    clearCommodityImage(){
      this.dialogImage = null
    },
    updateCommodityName(id, field, value){
      if (field=='customs_type'&&value==null) {
        alert(this.$i18n.t('customs-type is not nullable'))
        return
      }
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.patchApi(id,jsonData,"/commodity/commodity/")
    },
    updateRelation(id, field, value){
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.patchApi(id,jsonData,"/container/container_box_relation/")
    },
    editCommodiyImages(item){
      this.orig_dialogItem5 = this._.cloneDeep(item.commodity);
      this.dialogItem5 = this._.cloneDeep(item.commodity);
      this.dialogItem5.cover = item.commodity.image ? item.commodity.image.id : null
      console.log('item.commodity',item.commodity)
      this.image_ids = item.commodity.images?item.commodity.images:[]
      this.updateImages(this.image_ids)
      this.action = action_items.edit;
      this.showDialog5 = true
    },
    onDialogClose5(){
      this.showDialog5 = false
    },
    deleteCommodityImage2(commodity_id,image_id){
      // console.log('deleteCommodityImage',commodity_id,image_id)
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/deleteImage/",this.refreshImage)
    },
    updateCommodityCover2(commodity_id,image_id){
      // console.log('updateCommodityCover',commodity_id,image_id)
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/changeCover/",this.refreshImage)
    },
    createCommodityImageEvent2(commodity_id,image_binary){
      console.log('createCommodityImageEvent2',commodity_id,image_binary)
      const data = {}
      data['commodity'] = commodity_id
      data['image_binary'] = image_binary
      this.imageCreateHandler(data,this.action_items.create)
    },
    refreshImage(action, data){
      this.dialogItem5.cover = data.image ? data.image.id : null
      this.image_ids = data.images
      this.updateImages(this.image_ids)
    },
    getWeight (item) {
      return item.gross_weight * item.box_amount
    },
    getWorkplace() {
      this.getApi("/container/workplace/", this.setWorkplaceItems,false,{},{status: 1},{});
    },
    setWorkplaceItems(datas){
      this.workplaces = datas.results
      if (this.switchToLatest) {
        let index = this.workplaces.length
        this.updateCurrentWorkplaceIndex(index)
        this.switchToLatest = false
      }
    },
    createWorkplace(){
      this.postApi({name: 'workplace'},"/container/workplace/",this.refreshWorkplace)
    },
    refreshWorkplace(action, data){
      this.switchToLatest = true
      this.getWorkplace()
    },    
    editOpen(item){
      console.log('editOpen',item)
      if (item) {
        this.customs_type = item.id;
        this.customs_type_cache = item;
      }
    },
    editClose() {
      console.log("editClose");
      this.customs_type = null;
      this.customs_type_cache = null;
    },
    moveWorkplace(workplace){
      this.showDialog6 = false
      this.targetWorkplace = workplace
      var boxes = []
      for (const [key, box] of Object.entries(this.selected)) {
        boxes.push(box.id);
      }
      this.postApi({boxes: boxes},"/container/workplace/"+workplace+"/addBox/",this.afterMoveWorkplace)
      
    },
    afterMoveWorkplace(action,data){
      let index = this.workplaces.findIndex(e=>e.id==this.targetWorkplace)
      this.currentWorkplaceIndex = index
    },
    triggerMoveWorkplace(){
      if (this.selected.length > 0) {
        this.showDialog6 = true  
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"))
      }
    },
    onDialogClose6(){
      this.showDialog6 = false
    },
    closeWorkplace(workplace){
      if (this.totalItems==0) {
        this.postApi({},"/container/workplace/"+workplace+"/close/",this.afterCloseWorkplace)
      } else {
        alert(this.$i18n.t("alert.workplace-close-tip"))
      }
      
    },
    afterCloseWorkplace(action,datas){
      this.workplaces = datas
      this.currentWorkplaceIndex = 0
    },
    parseWorkplaceId(id){
      return (id % 10) +1
    },
    checkCode(customer, code){
      let duplicate = this.items.filter(item=>(item.code==code&&item.customer.id!==customer))
      if (duplicate.length > 0) {
        this.code_error = true
        this.code_error_msg = this.$i18n.t("alert.code-duplicate")
      } else {
        this.code_error = false
        this.code_error_msg = ""
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    updateCurrentWorkplaceIndex(val){
      console.log('currentWorkplaceIndex',val)
      if (val > 0) {
        let workplace = this.workplaces[val-1]
        if (workplace) {
          this.getParams['workplace'] = workplace.id
          this.container_isnull = true
          this.currentWorkplace = workplace.id
        }
      } else {
        this.currentWorkplace = 0
        delete this.getParams['workplace']
      }
      this.getApi();
    },
    onDialogClose7(){
      this.showDialog7 = false
    },
    checkAccreditationNumber(val,container_box){
      let item = {}
      item['accreditation_number'] = val.accreditation_number
      item['customs_type'] = val.customs_type.id
      item['customer'] = container_box.customer.id
      item['workplace'] = container_box.workplace
      this.postApi(item,"/commodity/certificate-relation/checkAccreditationNumber/", this.checkAccreditationNumbersResponse);
    },
    checkAccreditationNumbersResponse(action,datas){
      if (datas['result']==2) { // 無此認證型號
        this.accreditation_number_error = true
        this.accreditation_number_error_message = this.$i18n.t('alert.accreditation-number-not-found')
      } else if (datas['result']==3) { // 認證型號已失效
        this.accreditation_number_error = true
        this.accreditation_number_error_message = this.$i18n.t('alert.accreditation-number-expired')
      } else if (datas['result']==4) { // 認證型號已使用
        this.accreditation_number_error = true
        this.accreditation_number_error_message = this.$i18n.t('alert.accreditation-number-duplicate')
      } else {
        this.accreditation_number_error = false
        this.accreditation_number_error_message = null
      }
    },
    resetError(){
      this.accreditation_number_error = false
      this.accreditation_number_error_message = null
    },
    parseRelations(){
      this.relations = []
      for (const [key,item] of Object.entries(this.items)) {
        for (const [_,relation] of Object.entries(item.relation)) {
          this.relations.push({
            'commodity_id': relation.commodity.id,
            'commodity_name': relation.commodity.name,
            'customer_id': item.customer.id
          })
        }
      }
    },
    checkPaymentType(code){
      let duplicates = this.items.filter(item=>(item.code==code))
      if (duplicates.length > 0) {
        let payment_type = duplicates[0].payment_type
        this.used_payment_type = payment_type
      } else {
        this.used_payment_type = null
      }
    },
  },
  watch: {
    selected(obj) {
      this.selected_summary.box_amount = this.arraySum(obj, "box_amount");
      this.selected_summary.cubic_feet = this.arraySum(obj, "cubic_feet");
      this.selected_summary.gross_weight = this.arraySum(obj, "gross_weight");
    },
    items(obj) {
      this.current_summary.box_amount = this.arraySum(obj, "box_amount");
      this.current_summary.cubic_feet = this.arraySum(obj, "cubic_feet");
      let total_gross_weight = 0
      for (const [key, item] of Object.entries(obj)) {
        total_gross_weight += item.gross_weight * item.box_amount
      }
      this.current_summary.gross_weight = total_gross_weight
      this.getUndispatchSummary()
      this.parseRelations()
    },
    container_isnull(val) {
      this.getParams["container__isnull"] = val
      if (val) {
        this.getParams['status'] = this.container_status.enable    
        delete this.getParams['status__gt']
      } else {
        this.getParams['status__gt'] = this.container_status.enable
        delete this.getParams['status']
      }
      this.getApi();
    },
    currentWorkplace(val){
      this.selected = []
    },
    currentWorkplaceIndex(val){
      this.updateCurrentWorkplaceIndex(val)
    },
    options: {
      handler(){
        this.getWorkplace()
      }
    },
  },
  mounted() {
    
    this.getParams['status'] = this.container_status.enable
    this.getParams["container__isnull"] = true
    this.footerProps.itemsPerPageOptions = [20, 50, 100, 200]
    this.options.itemsPerPage=200

    this.options = {
      sortBy: ["sort_code","customer__id","start_box"],
      sortDesc: [true,false,true],
    };
    
  },
};
</script>
<style>
div.v-data-table.container-box-data-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(11) {
  background-color: rgb(217, 238, 217) !important;
}
div.v-data-table.container-box-data-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(12) {
  background-color: rgb(217, 238, 217) !important;
  border-left: thin solid rgba(0,0,0,0.12);
  border-right: thin solid rgba(0,0,0,0.12);
}
div.v-data-table.container-box-data-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(13) {
  background-color: rgb(217, 238, 217) !important;
}
</style>
