<template>
  <v-dialog
    v-model="show"
    max-width="80%"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mb-8">
            <v-col></v-col>
            <v-col>
              <v-text-field
                v-model="range_min"
                :label="$t('container.start_box')"
                single-line
                type="number"
                :error="range_min_error"
                :error-messages="range_min_error_msg"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="range_max"
                :label="$t('container.end_box')"
                single-line
                type="number"
                :error="range_max_error"
                :error-messages="range_max_error_msg"
              ></v-text-field>
            </v-col>
            
            <v-col></v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row>
            <v-col></v-col>
            <v-col>{{ $t('container.code') }}</v-col>
            <v-col>{{ $t('container.box_str') }}</v-col>
            <v-col>{{ $t('container.number_str') }}</v-col>
            <v-col>{{ $t('container.box_amount') }}</v-col>
            <v-col>{{ $t('commodity.name') }}</v-col>
            <v-col>{{ $t('commodity.number') }}</v-col>
            <v-col>{{ $t('container.size_length') }}</v-col>
            <v-col>{{ $t('container.size_width') }}</v-col>
            <v-col>{{ $t('container.size_height') }}</v-col>
            <v-col>{{ $t('container.cubic_feet') }}</v-col>
            <v-col>{{ $t('container.gross_weight') }}</v-col>
            <v-col>{{ $t('container.total_gross_weight') }}</v-col>
            <v-col>{{ $t('supplier.supplier') }}</v-col>
            <v-col>{{ $t('customer.customer') }}</v-col>
            <!-- <v-col></v-col> -->
          </v-row>
          <v-row>
            <v-col>{{ $t('container-box.before-split') }}</v-col>
            <v-col>{{origin_data.code}}</v-col>
            <v-col>{{origin_data.start_box}}</v-col>
            <v-col>{{origin_data.end_box}}</v-col>
            <v-col>{{origin_data.box_amount}}</v-col>
            <v-col>
              <template v-for="data in origin_data.relation">
                {{data.commodity.name}}
              </template>
            </v-col>
            <v-col>
              <template v-for="data in origin_data.relation">
                {{data.commodity.commodity_number}}
              </template>
            </v-col>
            <v-col>{{ origin_data.size_length }}</v-col>
            <v-col>{{ origin_data.size_width }}</v-col>
            <v-col>{{ origin_data.size_height }}</v-col>
            <v-col>{{ origin_data.cubic_feet }}</v-col>
            <v-col>{{ origin_data.gross_weight}}</v-col>
            <v-col>{{ fixedFloat(getWeight(origin_data),2,0)}}</v-col>
            <v-col>{{get_supplier_name(origin_data)}}</v-col>
            <v-col>{{get_customer_name(origin_data)}}</v-col>
          </v-row>
          <hr class="my-4" style="border-top: 3px solid;">
          <v-row v-for="preview_data in preview_datas">
            <v-col>{{ $t('container-box.after-split') }}</v-col>
            <v-col>{{ preview_data.code }}</v-col>
            <v-col>{{ preview_data.start_box }}</v-col>
            <v-col>{{ preview_data.end_box }}</v-col>
            <v-col>{{ preview_data.box_amount }}</v-col>
            <v-col>
              <template v-for="data in preview_data.relation">
                {{data.commodity.name}}
              </template>
            </v-col>
            <v-col>
              <template v-for="data in preview_data.relation">
                {{data.commodity.commodity_number}}
              </template>
            </v-col>
            <v-col>{{ preview_data.size_length }}</v-col>
            <v-col>{{ preview_data.size_width }}</v-col>
            <v-col>{{ preview_data.size_height }}</v-col>
            <v-col>{{ preview_data.cubic_feet }}</v-col>
            <v-col>{{ preview_data.gross_weight}}</v-col>
            <v-col>{{ fixedFloat(getWeight(preview_data),2,0)}}</v-col>
            <v-col>{{get_supplier_name(preview_data)}}</v-col>
            <v-col>{{get_customer_name(preview_data)}}</v-col>
            <!-- <v-col></v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    origin_data: {},
  },
  data: (vm) => ({
      topic: vm.$i18n.t("container-box.split"),
      range_min: null,
      range_max: null,
      splits: [],
      preview_datas: [],
      range_min_error: false,
      range_min_error_msg: "",
      range_max_error: false,
      range_max_error_msg: "",
  }),
  mixins: [BaseDialog],
  computed: {
    allowSave() {
      return !(this.range_min&&this.splits.length>0)
    },
  },
  methods: {
    get_supplier_name(item) {
      return item.supplier?this.getHanText(item.supplier, "name"):'';
    },
    get_customer_name(item) {
      return item.customer?this.getHanText(item.customer, "name"):'';
    },
    preview(){
      this.parseSplits()
      this.preview_datas = []
      for (var split of this.splits) {
        var box_amount = split[1] - split[0] + 1
        this.preview_datas.push({
          code: this.origin_data.code,
          start_box: split[0],
          end_box: split[1],
          box_amount: box_amount,
          relation: this.origin_data.relation,
          size_length: this.origin_data.size_length,
          size_width: this.origin_data.size_width,
          size_height: this.origin_data.size_height,
          cubic_feet: this.origin_data.cubic_feet,
          gross_weight: this.origin_data.gross_weight,
          total_gross_weight: box_amount * this.origin_data.gross_weight,
          supplier: this.origin_data.supplier,
          customer: this.origin_data.customer,
        })
      }
    },
    close() {
      this.range_min = null;
      this.range_max = null;
      this.splits = []
      this.$emit("onClose");
    },
    save(){
      var splits = this.parseSplits()
      this.$emit('onSave',this.origin_data.id,splits)
    },
    parseSplits(){
      this.splits = []
      if (this.range_min) {
        if (this.range_max) {
          console.log('range case')
          var split_start = parseInt(this.range_min)
          var split_end = parseInt(this.range_max)
          if (split_start>split_end) {
            this.range_min_error = true
            this.range_min_error_msg = this.$i18n.t('alert.start-must-less-than-end')
            this.range_max_error = true
            this.range_max_error_msg = this.$i18n.t('alert.start-must-less-than-end')
            return
          }
          if (split_start==this.origin_data.start_box&&split_end==this.origin_data.end_box) {
            this.range_min_error = true
            this.range_min_error_msg = this.$i18n.t('alert.useless-split')
            this.range_max_error = true
            this.range_max_error_msg = this.$i18n.t('alert.useless-split')
            return
          } else if (split_start==this.origin_data.start_box) {
            console.log('split 2 start')
            this.splits.push([this.origin_data.start_box,split_end])
            this.splits.push([split_end+1,this.origin_data.end_box])
          } else if (split_end==this.origin_data.end_box) {
            console.log('split 2 end')
            this.splits.push([this.origin_data.start_box,split_start-1])
            this.splits.push([split_start,this.origin_data.end_box])
          } else {
            console.log('split 3')
            this.splits.push([this.origin_data.start_box,split_start-1])
            this.splits.push([split_start,split_end])
            this.splits.push([split_end+1,this.origin_data.end_box])
          }
        } else {
          console.log('single case')
          var split_no = parseInt(this.range_min)
          if (split_no==this.origin_data.start_box) {
            console.log('split 2 start')
            this.splits.push([this.origin_data.start_box,this.origin_data.start_box])
            this.splits.push([this.origin_data.start_box+1,this.origin_data.end_box])
            
          } else if (split_no==this.origin_data.end_box) {
            console.log('split 2 end')
            this.splits.push([this.origin_data.start_box,this.origin_data.end_box-1])
            this.splits.push([this.origin_data.end_box,this.origin_data.end_box])
          } else {
            console.log('split 3')
            this.splits.push([this.origin_data.start_box,split_no-1])
            this.splits.push([split_no,split_no])
            this.splits.push([split_no+1,this.origin_data.end_box])
          }
        }
        console.log(this.splits)
        return this.splits
      } else {
        if (this.range_max) {
          this.range_min_error = true
          this.range_min_error_msg = this.$i18n.t('alert.start-cant-be-null')
          return
        }
        
      }
    },
    getWeight (item) {
      return item.gross_weight * item.box_amount
    },
  },
  watch: {
    splits(val){},
    range_min(val){
      if(val){
        if (val>=this.origin_data.start_box && val<=this.origin_data.end_box) {
          this.range_min_error = false
          this.range_min_error_msg = ""
          this.preview()
        } else {
          this.splits = []
          this.preview_datas = []
          this.range_min_error = true
          this.range_min_error_msg = this.$i18n.t('alert.out-of-range')
        }
      }
    },
    range_max(val){
      if (val) {
        if (val>=this.origin_data.start_box && val<=this.origin_data.end_box) {
          this.range_max_error = false
          this.range_max_error_msg = ""
          this.preview()
        } else {
          this.splits = []
          this.preview_datas = []
          this.range_max_error = true
          this.range_max_error_msg = this.$i18n.t('alert.out-of-range')
        }
      }
    },
  },
}
</script>