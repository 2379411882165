<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <CommodityFastCreateDialog
      :show="showCommodityCreateDialog"
      :action="dialogAction"
      :item="dialogItem"
      :new_customs_type="new_customs_type"
      :new_deliver_type="new_deliver_type"
      v-on:onClose="onDialogClose"
      v-on:onSave="onDialogSave"
      v-on:onCreateCustomsType="onCreateCustomsType"
      v-on:onCreateDeliverType="onCreateDeliverType"
    ></CommodityFastCreateDialog>
    <SupplierFastCreateDialog
      :show="showDialog2"
      :action="dialogAction2"
      :item="dialogItem2"
      v-on:onClose="onDialogClose2"
      v-on:onSave="onDialogSave2"
    ></SupplierFastCreateDialog>
    <CustomerFastCreateDialog
      :show="showDialog3"
      :action="dialogAction3"
      :item="dialogItem3"
      v-on:onClose="onDialogClose3"
      v-on:onSave="onDialogSave3"
    ></CustomerFastCreateDialog>
    <CommodityImageDialog
      :show="showDialog4"
      :action="dialogAction4"
      :item="dialogItem4"
      :images="commodity_images"
      :updateList="updateList"
      v-on:onSave="createCommodityImageEvent"
      v-on:updateCommodityCover="updateCommodityCover"
      v-on:deleteCommodityImage="deleteCommodityImage"
      v-on:onClose="onDialogClose4"
    ></CommodityImageDialog>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-row dense>
                  <v-col>
                    <CommonAutocomplete
                      v-model="supplier"
                      :label="$t('supplier.supplier')"
                      :placeholder="''"
                      url="/supplier/supplier/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :extra_filter="item"
                      :filter_fields="{}"
                      :cache="[supplier_cache]"
                      :extra_condition="{
                        enable: true
                      }"
                      :no_data_action="false"
                      v-on:noDataAction="triggerCreateSupplier"
                      :required="true"
                      :prepend="{
                        title: 'container-box.create_supplier',
                      }"
                      v-on:prependAction="triggerCreateSupplier"
                      :returnObject="false"
                      ref="supplier"
                      nextRef="customer"
                      v-on:keyPress="goAutoNext"
                      v-on:setItem="setSupplierItem"
                    ></CommonAutocomplete>
                  </v-col>
                  <v-col>
                    <CommonAutocomplete
                      v-model="customer"
                      :label="$t('customer.customer')"
                      :placeholder="''"
                      url="/customer/customer/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :extra_filter="item"
                      :filter_fields="{}"
                      :cache="[customer_cache]"
                      :extra_condition="{
                        enable: true
                      }"
                      :no_data_action="false"
                      v-on:noDataAction="triggerCreateCustomer"
                      :required="true"
                      :prepend="{
                        title: 'container-box.create_customer',
                      }"
                      v-on:prependAction="triggerCreateCustomer"
                      :returnObject="false"
                      ref="customer"
                      nextRef="commodity"
                      v-on:keyPress="goAutoNext"
                      v-on:setItem="setCustomerItem"
                    >
                    </CommonAutocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <CommodityAutocomplete
                      v-model="commodity"
                      :label="$t('commodity.commodity')"
                      :placeholder="$t('container-box.commodity-hint')"
                      url="/commodity/commodity/"
                      item_text="commodity_number"
                      item_value="id"
                      query_field="commodity_number__istartswith"
                      :extra_filter="parseCommodityExtraFilter(item)"
                      :filter_fields="{
                        'supplier': 'supplier__id',
                        'customer': 'customer__sp',
                      }"
                      :cache="[commodity_cache]"
                      :extra_condition="{
                        enable: true
                      }"
                      :no_data_action="false"
                      v-on:noDataAction="triggerCreateCommodity"
                      extra_item_text="customer,name,customs_type,image"
                      :required="true"
                      :prepend="{
                        title: 'container-box.create_commodity',
                      }"
                      v-on:prependAction="triggerCreateCommodity"
                      :returnObject="true"
                      ref="commodity"
                      nextRef="commodity_amount"
                      v-on:keyPress="goNext"
                    ></CommodityAutocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          style="cursor: pointer"
                          :src="get_image(commodity_image)"
                          max-height="40"
                          max-width="70"
                          @click="editCommodiyImages"
                          v-on="on"
                        ></v-img>
                      </template>
                      <span>點擊編輯圖片</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="commodity_amount"
                      :label="$t('container.commodity_per_box')"
                      :outlined="true"
                      dense
                      type="integer"
                      :rules="[rules.amountMin]"
                      ref="commodity_amount"
                      @keyup.right="goNext('box_amount')"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.commodity_per_box') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          large 
                          class="mr-2" 
                          v-on="on" 
                          :color="commodity?'green':''"
                          @click="appendCommodity"
                        >mdi-plus</v-icon>
                      </template>
                      <span>{{ $t("container-box.append") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-list width="300">
                      <v-subheader v-if="this.commoditys.length>0">
                        {{ $t('container-box.existed-commoditys') }}
                      </v-subheader>
                      <template v-for="(data, index) in this.commoditys">
                        <v-divider
                          v-if="index>0"
                          :key="index"
                        ></v-divider>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon @click="removeCommodity(data)">mdi-close</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.title }}&nbsp;&nbsp;{{ data.commodity_amount }}{{ $t('container.commodity_per_box') }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-avatar :tile="true" size="40">
                            <img
                              v-if="data.image"  
                              :src="data.image.file" 
                              @click="editCommodiyImages2(data)"
                              v-bind:title="'edit'"
                            >
                          </v-list-item-avatar> -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-img
                                style="cursor: pointer"
                                :src="get_image(data.image)" 
                                max-height="40"
                                max-width="70"
                                @click="editCommodiyImages2(data)"
                                v-on="on"
                              ></v-img>
                            </template>
                            <span>點擊編輯圖片</span>
                          </v-tooltip>
                          <v-list-item-action>
                            <v-icon @click="copyCommodity(data)">mdi-content-copy</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                    
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="item.box_amount"
                      :label="$t('container.box_amount')"
                      ref="box_amount"
                      @keyup.right="goNext('customer_box_code')"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.box_amount') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- <v-col>
                    <v-select
                      v-model="payment_type"
                      :label="$t('container-box.payment_type')"
                      :items="payment_items"
                      :item-text="item => $t(item.text)"
                      ref="payment_type"
                      :background-color="payment_type==null?'red':''"
                      @keyup.right="goNext('customer_box_code')"
                    ></v-select>
                  </v-col> -->
                  <v-col>
                    <v-radio-group
                      v-model="payment_type"
                      :label="$t('container-box.payment_type')"
                      ref="payment_type"
                      :background-color="payment_type==null?'red':''"
                    >
                      <v-radio
                        v-for="payment_item in payment_items"
                        :label="$t(payment_item.text)"
                        :value="payment_item.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="customer_box_code"
                      :label="$t('container.code')"
                      ref="customer_box_code"
                      @keyup.right="goNext('size_length')"
                      :error="code_error"
                      :error-messages="code_error_msg"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.code') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.start_box"
                      :label="$t('container.start_box')"
                      :readonly="false"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.start_box') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.end_box"
                      :label="$t('container.end_box')"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.size_length"
                      :label="$t('container.size_length')"
                      :placeholder="$t('container.size_unit')"
                      :rules="[rules.lengthMax]"
                      ref="size_length"
                      @keyup.right="goNext('size_width')"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.size_length') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.size_width"
                      :label="$t('container.size_width')"
                      :placeholder="$t('container.size_unit')"
                      :rules="[rules.widthMax]"
                      ref="size_width"
                      @keyup.right="goNext('size_height')"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.size_width') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.size_height"
                      :label="$t('container.size_height')"
                      :placeholder="$t('container.size_unit')"
                      :rules="[rules.heightMax]"
                      ref="size_height"
                      @keyup.right="goNext('gross_weight')"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.size_height') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.gross_weight"
                      :label="$t('container.gross_weight')"
                      :placeholder="$t('container.weight_unit')"
                      ref="gross_weight"
                    >
                      <template
                        #label
                        v-if="action==action_items.create"
                      >
                        <span class="red--text"><strong>* </strong></span>{{ $t('container.gross_weight') }}
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.cubic_feet"
                      :label="$t('container.cubic_feet')"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="total_gross_weight"
                      :label="$t('container.total_gross_weight')"
                      :placeholder="$t('container.weight_unit')"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="net_weight"
                      :label="$t('container.net_weight')"
                      :placeholder="$t('container.weight_unit')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="total_net_weight"
                      :label="$t('container.total_net_weight')"
                      :placeholder="$t('container.weight_unit')"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="hasPrePayment"
              :label="$t('container-box.has-pre-payment')"
            >
            </v-checkbox>
          </v-row>
          <v-row v-if="hasPrePayment">
            <v-col>
              <v-card>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="item.prepayment_price"
                      :label="$t('container-box.pre-payment')"
                      :disabled="!hasPrePayment"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="item.prepayment_currency"
                      :background-color="item.prepayment_currency==null?'red':''"
                      row
                    >
                      <v-radio v-for="currency in currency_items" :label="$t(currency.text)" :value="currency.value"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.total_prepayment_price"
                      :label="$t('container-box.total-pre-payment')"
                      :disabled="!hasPrePayment"
                      :readonly="true"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.prepayment_extra_fee"
                      :label="$t('container-box.pre-payment-extra-fee')"
                      :disabled="!hasPrePayment"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.prepayment_extra"
                      :label="$t('container-box.pre-payment-extra')"
                      :disabled="!hasPrePayment"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="item.hasStacker"
              :label="$t('container-box.has-stacker')"
            >
            </v-checkbox>
          </v-row>
          <v-row v-if="item.hasStacker">
            <v-col>
              <v-card>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="item.stacker_fee"
                      :label="$t('container-box.stacker-fee')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="item.stacker_currency"
                      :background-color="item.stacker_currency==null?'red':''"
                      row
                    >
                      <v-radio v-for="currency in currency_items" :label="$t(currency.text)" :value="currency.value"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.stacker_extra"
                      :label="$t('container-box.stacker-extra')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="item.hasChinaDeliverFee"
              :label="$t('container-box.has-china-deliver-fee')"
            >
            </v-checkbox>
          </v-row>
          <v-row v-if="item.hasChinaDeliverFee">
            <v-col>
              <v-card>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.china_deliver_company"
                      :label="$t('container-box.china-deliver-company')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.china_deliver_extra"
                      :label="$t('container-box.china-deliver-extra')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.china_deliver_fee"
                      :label="$t('container-box.china-deliver-fee')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="item.china_deliver_currency"
                      :background-color="item.china_deliver_currency==null?'red':''"
                      row
                    >
                      <v-radio v-for="currency in currency_items" :label="$t(currency.text)" :value="currency.value"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="action==action_items.edit">
            <v-col>
              <v-card>
                <v-row dense>
                  <v-col>
                    <v-select
                      v-model="item.container"
                      :label="$t('container.container')"
                      :items="container_items"
                      :item-text="item => $t(item.text)"
                      :append-icon="'mdi-close'"
                      @click:append="item.container=null"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
        <v-btn
          v-if="action == action_items.create"
          color="primary"
          :disabled="allowSave"
          @click="saveAndNext"
        >{{ $t("save and next") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import CommodityAutocomplete from "../filters/CommodityAutocomplete";
import CommodityFastCreateDialog from "./CommodityFastCreateDialog";
import CustomerFastCreateDialog from "./CustomerFastCreateDialog";
import SupplierFastCreateDialog from "./SupplierFastCreateDialog";
import { payment_items, currency_items } from "@/definition.js"
import noImage from "@/assets/no-image.jpg"
import CommodityImageDialog from "../dialogs/CommodityImageDialog";
export default {
  props: {
    container_items: [],
    default_customer_box_code: "",
    default_customer_box_start: "",
    new_commodity: {},
    new_customs_type: {},
    new_deliver_type: {},
    new_supplier: {},
    new_customer: {},
    commodity_image: {},
    commodity_image_ids: [],
    commodity_images: [],
    code_error: false,
    code_error_msg: "",
    used_payment_type: null,
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.box"),
    customs_type: null,
    isNew: false,
    total_gross_weight: null,
    net_weight: null,
    total_net_weight: null,
    customer_special: false,
    rules: {
      // required: value => !!value || vm.$i18n.t('edit'),
      lengthMax: (value) => {
        if (value && (value < 0 || value > 610)) {
          return vm.$i18n.t("container-box.length-hint", { max: 610 });
        }
        return true;
      },
      widthMax: (value) => {
        if (value && (value < 0 || value > 244)) {
          return vm.$i18n.t("container-box.width-hint", { max: 244 });
        }
        return true;
      },
      heightMax: (value) => {
        if (value && (value < 0 || value > 262)) {
          return vm.$i18n.t("container-box.height-hint", { max: 262 });
        }
        return true;
      },
      amountMin: (value) => {
        if (value && (value < 1)) {
          return vm.$i18n.t("container-box.amount-hint");
        }
        return true
      }
    },
    showCommodityCreateDialog: false,
    dialogAction: null,
    defaultItem: {},
    dialogItem: {},
    customer_box_code: "",
    commodity: null,
    commodity_cache: null,
    showDialog2: false,
    dialogAction2: null,
    defaultItem2: {},
    dialogItem2: {},
    showDialog3: false,
    dialogAction3: null,
    defaultItem3: {},
    dialogItem3: {},
    supplier: null,
    supplier_cache: null,
    supplier_item: null,
    customer: null,
    customer_cache: null,
    customer_item: null,
    payment_items: payment_items,
    currency_items: currency_items,
    hasPrePayment: false,
    showDialog4: false,
    dialogAction4: null,
    defaultItem4: {},
    dialogItem4: {},
    updateList: false,
    image_ids: [],
    commoditys: [],
    commodity_amount: '',
    hasStacker: false,
    stacker_fee: null,
    stacker_currency: null,
    stacker_extra: null,
    hasChinaDeliverFee: false,
    china_deliver_fee: null,
    china_deliver_company: null,
    china_deliver_extra: null,
    payment_type: null,
  }),
  computed: {
    disableCreateCommodity() {
      return !(this.item.supplier > 0 && this.item.customer > 0);
    },
    allowSave() {
      return !(
        this.supplier &&
        this.customer &&
        this.commoditys.length > 0 &&
        this.item.box_amount &&
        this.customer_box_code &&
        this.item.size_length &&
        this.item.size_width &&
        this.item.size_height &&
        this.item.gross_weight &&
        this.payment_type &&
        (this.item.hasStacker?(this.item.stacker_fee&&this.item.stacker_currency):true) &&
        (this.item.hasChinaDeliverFee?(this.item.china_deliver_company&&this.item.china_deliver_fee&&this.item.china_deliver_currency):true)
      );
    },
  },
  components: {
    DatePicker,
    CommonAutocomplete,
    CommodityAutocomplete,
    CommodityFastCreateDialog,
    CustomerFastCreateDialog,
    SupplierFastCreateDialog,
    CommodityImageDialog
  },
  mixins: [BaseDialog],
  methods: {
    setSupplierItem(item){
      this.supplier_item = item
    },
    setCustomerItem(item){
      this.customer_item = item
    },
    handleEditedItem(){
      let editedItem = this._.cloneDeep(this.item);
      if (isNaN(editedItem.end_box)) {
        editedItem.end_box = editedItem.start_box;
      }
      editedItem.code = this.customer_box_code;
      editedItem.commoditys = this.commoditys;
      editedItem.supplier = this.supplier;
      editedItem.supplier_item = this.supplier_item
      editedItem.customer = this.customer;
      editedItem.customer_item = this.customer_item;
      editedItem.net_weight = this.net_weight
      editedItem.payment_type = this.payment_type
      let extra = {}
      if (editedItem.stacker_fee) {
        extra['stacker'] = {
          'fee': editedItem.stacker_fee,
          'currency': editedItem.stacker_currency,
          'extra': editedItem.stacker_extra?editedItem.stacker_extra:"",
        }
      }
      if (editedItem.china_deliver_fee && editedItem.china_deliver_company) {
        extra['china_deliver'] = {
          'company': editedItem.china_deliver_company,
          'fee': editedItem.china_deliver_fee,
          'currency': editedItem.china_deliver_currency,
          'extra': editedItem.china_deliver_extra?editedItem.china_deliver_extra:"",
        }
      }
      editedItem.extra = JSON.stringify(extra)
      return editedItem
    },
    save() {
      let editedItem = this.handleEditedItem()
      this.resetField();
      this.$emit("onSave", editedItem);
    },
    close() {
      this.resetField();
      this.$emit("onClose");
    },
    saveAndNext() {
      let editedItem = this.handleEditedItem()
      this.commodity = null;
      this.commoditys = [];
      this.commodity_cache = null;
      this.$emit("onSaveNext", editedItem, true);
    },
    calculateCubicFeet() {
      var length = this.item.size_length ? this.item.size_length : 0;
      var width = this.item.size_width ? this.item.size_width : 0;
      var height = this.item.size_height ? this.item.size_height : 0;
      var box_amount = this.item.box_amount ? this.item.box_amount : 0;
      const cubicFeet = (length *
        width *
        height * 
        box_amount) /
        28317
      this.item.cubic_feet = cubicFeet.toFixed(2);
    },
    triggerCreateCommodity(commodity_number) {
      if (this.supplier && this.customer) {
        this.createCommodity(commodity_number);
      } else {
        console.log('this.supplier or this.customer is null')
      }
      
    },
    createCommodity(commodity_number = null) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.dialogItem["supplier"] = this.supplier;
      this.dialogItem["customer"] = this.customer;
      if (commodity_number) {
        this.dialogItem["commodity_number"] = commodity_number;
      }
      this.dialogAction = this.action_items.create;
      this.showCommodityCreateDialog = true;
    },
    onDialogClose(item) {
      this.showCommodityCreateDialog = false;
      // this.dialogItem = this._.cloneDeep(this.defaultItem);
    },
    onDialogSave(item) {
      this.showCommodityCreateDialog = false;
      // this.item.name = item.name
      this.$emit("onCreateCommodity", item);
    },
    resetField() {
      this.commodity = null;
      this.commoditys = []
      this.commodity_cache = null;
      this.supplier = null;
      this.supplier_cache = null;
      this.customer = null;
      this.customer_cache = null;
      this.total_gross_weight = null
      this.net_weight = null
      this.total_net_weight = null
      this.customer_box_code = ""
      this.hasStacker = false
      this.stacker_fee = null
      this.stacker_extra = null
      this.hasChinaDeliverFee = false
      this.china_deliver_fee = null
      this.china_deliver_company = null
      this.china_deliver_extra = null
      this.payment_type = null
    },
    onCreateCustomsType(item) {
      this.$emit("onCreateCustomsType", item);
    },
    onCreateDeliverType(item) {
      this.$emit("onCreateDeliverType", item);
    },
    triggerCreateSupplier(supplier_name) {
      this.createSupplier(supplier_name);
    },
    createSupplier(supplier_name = null) {
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      if (supplier_name) {
        this.dialogItem2["supplier"] = supplier_name;
      }
      this.dialogAction2 = this.action_items.create;
      this.showDialog2 = true;
    },
    onDialogClose2(item) {
      this.showDialog2 = false;
    },
    onDialogSave2(item) {
      this.showDialog2 = false;
      this.$emit("onCreateSupplier", item);
    },
    triggerCreateCustomer(customer_name) {
      this.createCustomer(customer_name);
    },
    createCustomer(customer_name = null) {
      this.dialogItem3 = this._.cloneDeep(this.defaultItem3);
      if (customer_name) {
        this.dialogItem3["customer"] = customer_name;
      }
      this.dialogAction3 = this.action_items.create;
      this.showDialog3 = true;
    },
    onDialogClose3(item) {
      this.showDialog3 = false;
      // this.dialogItem = this._.cloneDeep(this.defaultItem);
    },
    onDialogSave3(item) {
      this.showDialog3 = false;
      this.$emit("onCreateCustomer", item);
    },
    updateTotalPrepaymentPrice(){
      if (this.hasPrePayment) {
        this.item.total_prepayment_price = 
          this.getCommodityAmount() * 
          (this.item.box_amount ? this.item.box_amount : 0) * 
          (this.item.prepayment_price ? this.item.prepayment_price : 0)
      }
    },
    getCommodityAmount(){
      return this.commoditys.reduce( function(a,b){
          return a + parseInt(b['commodity_amount'])
        }, 0);
    },
    updateTotalGrossWeight(){
      this.total_gross_weight =
        (this.item.gross_weight ? this.item.gross_weight : 0) *
        (this.item.box_amount ? this.item.box_amount : 0);
      this.total_gross_weight = this.fixedFloat(this.total_gross_weight,2,0)
    },
    updateNetWeight(){
      this.net_weight =this.item.gross_weight ? (this.item.gross_weight > 1 ? this.item.gross_weight -1 : this.item.gross_weight * 0.5) : null
      this.net_weight = this.fixedFloat(this.net_weight,2,0)
    },
    updateTotalNetWeight(){
      this.total_net_weight = (this.net_weight ? this.net_weight : 0) * (this.item.box_amount ? this.item.box_amount : 0)
      this.total_net_weight = this.total_net_weight.toFixed(2)
    },
    get_image(item){
      return item ? item.file : noImage;
    },
    editCommodiyImages(){
      console.log('editCommodiyImages',this.commodity_image_ids)
      if (!this.commodity) {
        alert(this.$i18n.t('alert.selected-commodity-empty'))
	      return
      }
      this.image_ids = this.commodity_image_ids
      this.dialogItem4.id = this.commodity.id
      if (this.commodity_image) {
        this.dialogItem4.cover = this.commodity_image.id
      }
      this.updateList = false
      this.showDialog4 = true
    },
    editCommodiyImages2(data){
      this.image_ids = data.images
      this.dialogItem4.id = data.commodity_id
      if (data.image) {
        this.dialogItem4.cover = data.image.id
      }
      this.updateList = true
      this.showDialog4 = true
    },
    updateImages(){
      // console.log('run updateImages',this.image_ids)
      this.$emit('updateImages',this.image_ids)
    },
    createCommodityImageEvent(commodity_id,image_binary){
      // console.log('createCommodityImageEvent',commodity_id,image_binary)
      this.$emit('createCommodityImageEvent',commodity_id,image_binary)
    },
    updateCommodityCover(commodity_id,image_id,updateList){
      this.$emit('updateCommodityCover',commodity_id,image_id,updateList)
    },
    deleteCommodityImage(commodity_id,image_id,updateList){
      this.$emit('deleteCommodityImage',commodity_id,image_id,updateList)
    },
    onDialogClose4(){
      this.showDialog4 = false
    },
    appendCommodity(){
      if (this.commodity_amount<1) {
        alert(this.$i18n.t("container-box.amount-hint"))
      } else {
        this.commoditys.push({
          title: this.commodity.commodity_number,
          commodity_id: this.commodity.id,
          commodity_amount: this.commodity_amount,
          image: this.commodity_image,
          customer: this.commodity.customer
        })
        this.commodity = null,
        this.commodity_amount = ''
        this.$emit("clearCommodityImage")
      }
    },
    removeCommodity(data){
      console.log('removeCommodity',data)
      const index = this.commoditys.findIndex(e => e.commodity_id == data.commodity_id)
      this.commoditys.splice(index, 1);
    },
    clearCommoditys(){
      this.commoditys = []
    },
    clearCustomerCommoditys(){
      this.commoditys = this.commoditys.filter(item=>item.customer==null)
    },
    parseCommodityExtraFilter(item){
      if (item['supplier'] && typeof(item['supplier'])=='object'){
        item['supplier'] = item['supplier']['id']
      }
      if (item['customer'] && typeof(item['customer'])=='object'){
        item['customer'] = item['customer']['id']
      }
      return item
    },
    refreshTarget(data){
      let indexToUpdate = this.commoditys.findIndex(item => item.commodity_id === data.id);
      if (indexToUpdate >= 0) {
        this.commoditys[indexToUpdate].image = data.image;
        this.commoditys[indexToUpdate].images = data.images;
      }
    },
    goNext(name){
      console.log('go ',name)
      this.$refs[name].focus()
    },
    goAutoNext(name){
      console.log('go2 ',name)
      this.$refs[name].$children[0].focus()
    },
    copyCommodity(item){
      console.log('copyCommodity')
      console.log(item)
      this.commodity = item.commodity
      this.commodity_cache = item.commodity;
      this.commodity_amount = item.commodity_amount
    }
  },
  watch: {
    supplier(val, oldVal) {
      this.item.supplier = val;
      if ((val&&oldVal&&val!== oldVal)||(val==null)) {
        this.clearCommoditys()
      }
    },
    customer(val, oldVal) {
      this.item.customer = val;
      // trigger get customer box-code
      if (val > 0) {
        this.$emit("onGetCustomerDetail", val);
      }
      // customer change or customer is empty
      if ((val&&oldVal&&val!== oldVal)||(val==null)) {
        if (this.item.payment_type) {
          this.payment_type = this.item.payment_type
        } else {
          this.payment_type = null
        }
        this.clearCustomerCommoditys()
      }
    },
    commodity(val, oldVal) {
      if (val) {
        console.log('run getCommodity',typeof(val),val)
        this.$emit('getCommodity',(typeof(val)=='number')?(val):(val.id))
      }
    },
    "item.box_amount"(val, oldVal) {
      if (val > 0 && parseInt(this.item.start_box) > 0) {
        this.item.end_box = parseInt(this.item.start_box) + parseInt(val) - 1;
      }
      this.updateTotalPrepaymentPrice()
      this.updateTotalGrossWeight()
      this.updateTotalNetWeight()
      this.calculateCubicFeet()
    },
    "item.start_box"(val, oldVal) {
      if (val > 0 && parseInt(this.item.box_amount) > 0) {
        this.item.end_box = parseInt(val) + parseInt(this.item.box_amount) - 1;
      }
    },
    "item.size_length"() {
      this.calculateCubicFeet();
    },
    "item.size_height"() {
      this.calculateCubicFeet();
    },
    "item.size_width"() {
      this.calculateCubicFeet();
    },
    "item.gross_weight"() {
      this.updateTotalGrossWeight()
      this.updateNetWeight()
      this.updateTotalNetWeight()
    },
    "net_weight"() {
      this.updateTotalNetWeight()
    },
    "item.prepayment_price"() {
      this.updateTotalPrepaymentPrice()
    },
    customer_box_code(val, oldVal) {
      this.$emit('checkCode',this.customer,val)
      if (this.action==this.action_items.create){
        console.log('find same box code')
        this.$emit('checkPaymentType',val)
      }
    },
    default_customer_box_code(val, oldVal) {
      if (this.action == this.action_items.create) {
        this.customer_box_code = val;
      }
    },
    default_customer_box_start(val, oldVal){
      if (this.action == this.action_items.create) {
        this.item.start_box = val
      }
    },
    new_commodity(val, oldVal) {
      this.commodity = val;
      this.commodity_cache = val;
    },
    new_supplier(val, oldVal) {
      this.supplier = val.id;
      this.supplier_cache = val;
    },
    new_customer(val, oldVal) {
      this.customer = val.id;
      this.customer_cache = val;
    },
    item(val, oldVal) {
      if ("commoditys" in val) {
        this.commoditys = val.commoditys;
        // this.commodity_cache = val.commodity;
      }
      if ("code" in val) {
        this.customer_box_code = val.code;
      }
      if (this.action === this.action_items.edit) {
        if ("supplier" in val && val.supplier && typeof(val.supplier)=='object') {
          this.supplier = val.supplier.id;
          this.supplier_cache = val.supplier;
        }
        if ("customer" in val && val.customer && typeof(val.customer)=='object') {
          this.customer = val.customer.id;
          this.customer_cache = val.customer;
        }
      }
      if ("payment_type" in val) {
        this.payment_type = val.payment_type
      }
    },
    image_ids(val) {
      console.log('image_ids',val)
      if (val) {
        this.updateImages()
      }
      
    },
    commodity_image_ids(val) {
      console.log('commodity_image_ids',val)
      // if (val) {
      this.image_ids = val
      // }
      
    },
    used_payment_type(val){
      if (val) {
        this.payment_type = val
      }
    },
  },
};
</script>
