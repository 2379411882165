var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.max_width},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('CustomsTypeDialog',{attrs:{"show":_vm.showDialog,"action":_vm.dialogAction,"item":_vm.dialogItem,"unit_items":_vm.unit_items},on:{"onClose":_vm.onDialogClose,"onSave":_vm.onDialogSave}}),_c('DeliverTypeDialog',{attrs:{"show":_vm.showDialog2,"action":_vm.dialogAction2,"item":_vm.dialogItem2},on:{"onClose":_vm.onDialogClose2,"onSave":_vm.onDialogSave2}}),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('commodity.number')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('commodity.number'))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.item.commodity_number),callback:function ($$v) {_vm.$set(_vm.item, "commodity_number", $$v)},expression:"item.commodity_number"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('commodity.name')},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('commodity.deliver-type'),"placeholder":'',"url":"/commodity/deliver-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.item,"filter_fields":{},"cache":[_vm.deliver_type_cache],"extra_condition":{
                enable: true
              }},model:{value:(_vm.deliver_type),callback:function ($$v) {_vm.deliver_type=$$v},expression:"deliver_type"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createDeliverType}},[_c('v-icon',[_vm._v(_vm._s(_vm.$t('container-box.create_deliver_type')))])],1)],1)],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('commodity.customs-type'),"placeholder":'',"url":"/commodity/customs-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.item,"filter_fields":{},"cache":[_vm.customs_type_cache],"extra_condition":{
                enable: true
              },"required":true},model:{value:(_vm.customs_type),callback:function ($$v) {_vm.customs_type=$$v},expression:"customs_type"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createCustomsType}},[_c('v-icon',[_vm._v(_vm._s(_vm.$t('container-box.create_customs_type')))])],1)],1)],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('supplier.supplier'),"placeholder":'',"url":"/supplier/supplier/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.item,"filter_fields":{},"cache":[_vm.item.supplier],"extra_condition":{
                enable: true
              },"required":true},model:{value:(_vm.item.supplier),callback:function ($$v) {_vm.$set(_vm.item, "supplier", $$v)},expression:"item.supplier"}})],1),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('customer.customer'),"placeholder":'',"url":"/customer/customer/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.item,"filter_fields":{},"cache":[_vm.item.customer],"extra_condition":{
                enable: true
              }},model:{value:(_vm.item.customer),callback:function ($$v) {_vm.$set(_vm.item, "customer", $$v)},expression:"item.customer"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('commodity.customer-special'),"disabled":""},model:{value:(_vm.isCustomerSpecial),callback:function ($$v) {_vm.isCustomerSpecial=$$v},expression:"isCustomerSpecial"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),(_vm.action != _vm.action_items.view)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.allowSave},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }